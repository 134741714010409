<template>
    <div class="rating-banner_wrapper-prizes">
        <div
            :key="idx"
            v-for="(el, idx) in prizes"
            class="rating-banner_prize">
            <div class="rating-banner_prize-img"></div>
            <div class="rating-banner_prize-place">
                <div
                    class="flex is-justify-content-center"
                    style="gap: 4px">
                    <div
                        :key="idx"
                        v-for="(el, idx) in Array.from({ length: idx + 1 }, (_, i) => i + 1)"
                        class="rating-banner_prize-place_count">
                    </div>
                </div>
                <p class="rating-banner_prize-place_text">
                    {{ $t(`common.rating.banner.place`) }}
                </p>
            </div>
            <p class="rating-banner_prize-info">
                {{ el }}
            </p>
        </div>
    </div>
</template>

<script>
  export default {
    computed: {
      prizes () {
        return ["$5000", "$3000", "$2000"];
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import "~@/scss/competition-banner";

  .rating-banner {
    $self: &;

    &_wrapper-prizes {
      position: absolute;
      top:  clamp(22px, 2vw, 40px);
      right: 0;

      display: flex;
      width: clamp(200px, 31.7vw, 640px);

      @media screen and (max-width: 1407px) {
        width: clamp(260px, 27.7vw, 400px);
      }

      #{$self}_prize {
        //@extend %verticalCenter;

        position: absolute;
        top: -10px;
        z-index: 3;

        border-radius: clamp(8px, 1.2vw, 24px);
        padding: clamp(4px, 0.45vw, 8px);

        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.35);
        background: $color-khaki;
        transform: rotate(-10deg);

        #{$self}_prize-img {
          width: clamp(74px, 11.7vw, 224px);
          height: clamp(74px, 11.7vw, 224px);

          background-image: url("~@/assets/Competition/Raffle/avatar-5k.png");
          background-size: cover;

          border-radius: clamp(5px, 0.8vw, 16px);
          margin-bottom: 8px;

          @media screen and (max-width: 1407px) {
            width: clamp(90px, 9.2vw, 128px);
            height: clamp(90px, 9.2vw, 128px);
          }
        }

        &:nth-child(2) {
          transform: rotate(4deg);
          top: 0;
          left: clamp(50px, 11.6vw, 223px);
          z-index: 2;

          @media screen and (max-width: 1407px) {
            left: clamp(95px, 9.6vw, 135px);
          }

          #{$self}_prize-img {
            background-image: url("~@/assets/Competition/Raffle/avatar-3k.png");
            width: clamp(74px, 10vw, 192px);
            height: clamp(74px, 10vw, 192px);

            @media screen and (max-width: 1407px) {
              width: clamp(75px, 7.75vw, 109px);
              height: clamp(75px, 7.75vw, 109px);
            }
          }

          #{$self}_prize-info {
            font-size: clamp(11px, 1.9vw, 36px);
          }
        }
        &:nth-child(3) {
          transform: rotate(18deg);
          left: clamp(150px, 21.6vw, 413px);
          top: clamp(25px, 2.5vw, 50px);
          z-index: 1;

          @media screen and (max-width: 1407px) {
            left: clamp(175px, 17.8vw, 250px);
          }

          #{$self}_prize-img {
            background-image: url("~@/assets/Competition/Raffle/avatar-2k.png");
            width: clamp(74px, 8.7vw, 160px);
            height: clamp(74px, 8.7vw, 160px);

            @media screen and (max-width: 1407px) {
              width: clamp(60px, 6.5vw, 91px);
              height: clamp(60px, 6.5vw, 91px);
            }
          }

          #{$self}_prize-info {
            font-size: clamp(9px, 1.6vw, 30px);
          }
        }

        &-place {
          position: absolute;
          top: -10px;
          left: -10px;

          width: clamp(48px, 3.25vw, 64px);
          height: clamp(48px, 3.25vw, 64px);

          padding: clamp(5px, 3.25vw, 7px);
          box-shadow: 0 0 24px 0 rgba(191, 255, 84, 0.31);
          background: linear-gradient(134deg, #2e4a07 0%, #719d34 100%);
          border: clamp(2px, 0.2vw, 4px) solid white;

          @media screen and (max-width: 1407px) {
            width: 40px;
            height: 40px;
            padding: 4px;
          }

          &>div {
            height: 50%;
          }

          &_count {
            width: clamp(4px, 0.3vw, 6px);
            height: 100%;
            background-color: white;
          }

          &_text {
            font-weight: 500;
            font-size: clamp(10px, 0.65vw, 13px);
            line-height: 1.38;
            letter-spacing: -0.04em;
            text-transform: uppercase;

            margin-top: 4px;

            @media screen and (max-width: 1407px) {
              font-size: 8px;
            }
          }
        }

        &-info {
          font-weight: 800;
          font-size: clamp(16px, 2.1vw, 40px);
          line-height: 0.9;
          letter-spacing: -0.04em;
          text-align: center;

          margin-bottom: 8px;
        }
      }

      @media screen and (max-width: 720px) {
        display: none;
      }
    }
  }
</style>