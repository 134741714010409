<template>
    <div class="chart-filters">
        <LFilters
            ref="filters"
            :filters="dashboardFilters"
            :loading="isLoading"
            auto-confirm
            @clear="SET_EMPTY('filters')"
            @confirm="confirmFilters">
            <template #filters>
                <div class="columns is-multiline is-variable is-vcentered">
                    <div class="column is-flex-desktop">
                        <CustomDatepicker
                            v-model="datepicker"
                            label=""
                            include-end>
                        </CustomDatepicker>
                    </div>
                    <div class="column flex is-justify-content-flex-end">
                        <CurrencyCheckbox v-model="currency"></CurrencyCheckbox>
                    </div>
                </div>
            </template>
        </LFilters>
    </div>
</template>

<script>
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
  import CurrencyCheckbox from "@/components/Common/Controls/CurrencyCheckbox.vue";
  import LFilters from "@/components/Common/LFilters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_FILTERS } from "@core/store/mutation-constants";
  import { GET_STATISTIC, SET_EMPTY } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "DashboardFilters",
    components: {
      LFilters,
      CurrencyCheckbox,
      CustomDatepicker
    },

    computed: {
      ...mapState("webmaster/dashboard/statistic", {
        dashboardFilters: ({ filters }) => filters
      }),

      ...mapFields("filtersConfirm", {
        fields: ["datepicker", "currency"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("verticals", {
        currentVertical: ({ currentVertical }) => currentVertical
      }),

      isLoading () {
        return this.$wait(`webmaster/dashboard/statistic/${ GET_STATISTIC }`);
      }
    },

    methods: {
      ...mapActions("webmaster/dashboard/statistic", {
        UPDATE_FILTERS,
        GET_STATISTIC,
        SET_EMPTY
      }),

      confirmFilters ({ filters }) {
        this.UPDATE_FILTERS(filters);
        this.GET_STATISTIC();
      }
    },

    watch: {
      currentVertical () {
        this.$refs.filters.clear();
      }
    }
  };
</script>

<style lang="scss" scoped>
    .column {
        align-items: center;
    }
</style>
