<template>
    <AsyncRender>
        <DashboardDifferenceCards>
        </DashboardDifferenceCards>

        <template v-if="isVisibleByAllVerticals">
            <Permissions :permissions="['SUPER.PERMISSION', 'DASHBOARD.CASH_FLOW.GET', 'DASHBOARD.COMMISSION.GET']">
                <ExpandFullscreen
                    class="chart-modal mb-5"
                    :available-orientations="['landscape-primary']"
                    fixed-orientation
                    start-orientation="landscape-primary">
                    <DashboardFilters
                        :filters="filters"
                        :excluded-filters="excludedFilters"
                        :loading="isLoading">
                    </DashboardFilters>
                    <Card class="chart">
                        <DashboardChart
                            :data="statistics"
                            :filters="filters"
                            :loading="isLoading">
                        </DashboardChart>
                    </Card>
                </ExpandFullscreen>
            </Permissions>

            <DashboardTops></DashboardTops>
        </template>
    </AsyncRender>
</template>

<script>
  import DashboardDifferenceCards from "@/components/Admin/Dashboard/DashboardDifferenceCards";
  import DashboardFilters from "@/components/Admin/Dashboard/DashboardFilters";
  import ExpandFullscreen from "@/components/Common/Chart/ExpandFullscreen";
  import DashboardChart from "@/components/Admin/Dashboard/DashboardChart";
  import DashboardTops from "@/components/Admin/Dashboard/DashboardTops";
  import AsyncRender from "@/components/Common/AsyncRender";
  import Permissions from "@/components/Common/Permissions.vue";
  import Card from "@/components/Common/Card";
  import { hasPermissions } from "@core/mixins/permissions";
  import { mapActions, mapGetters, mapState } from "vuex";
  import {
    GET_DASHBOARD_STATISTICS,
    SET_EMPTY
  } from "@core/store/action-constants";

  export default {
    name: "Dashboard",
    components: {
      DashboardDifferenceCards,
      ExpandFullscreen,
      DashboardFilters,
      DashboardChart,
      DashboardTops,
      Permissions,
      AsyncRender,
      Card
    },

    async beforeCreate () {
      if (this.hasNavigateStatistics) {
        await this.$router.push({
          name: "admin:statistics"
        }).catch(_ => {});
      }
    },

    computed: {
      ...mapState({
        hasNavigateStatistics: ({ auth }) => auth.userPermissions && !hasPermissions([
          "SUPER.PERMISSION",
          "DASHBOARD.DEBIT.GET",
          "DASHBOARD.PREPAID_EXPENSE.GET",
          "DASHBOARD.COMMISSION.GET",
          "DASHBOARD.CASH_FLOW.GET",
          "DASHBOARD.SUMMARY_OFFER.LIST",
          "DASHBOARD.SUMMARY_ADVERTISER.LIST",
          "DASHBOARD.SUMMARY_WEBMASTER.LIST.ALL",
          "DASHBOARD.SUMMARY_WEBMASTER.LIST.OWN"
        ], auth.userPermissions)
      }),

      ...mapState("admin/dashboard/dashboardStatistic", {
        excludedFilters: ({ dashboardFilters }) => dashboardFilters.excludedFilters,
        filters: ({ dashboardFilters }) => dashboardFilters.filters,
        statistics: ({ statistics }) => statistics
      }),

      ...mapGetters("verticals", ["isVisibleByAllVerticals", "nutra"]),

      userPermissions () {
        return (this.$store || { getters: {} }).getters.permissions;
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatistic/${ GET_DASHBOARD_STATISTICS }`);
      }
    },
    methods: {
      ...mapActions("admin/dashboard/dashboardStatistic", {
        SET_EMPTY
      })
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    @mixin shadow {
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.05);
    }

    .leads-tab {
        ::v-deep.card-header {
            background: #ecf6ff;
            @include shadow;

            .card-header-title {
                padding: 10px;
                justify-content: center;
                color: #1a55af;
            }
        }
    }

    .approve-tab {
        ::v-deep.card-header {
            background: #f6f4ff;
            @include shadow;

            .card-header-title {
                color: #5843be;
            }
        }
    }

    .commission-tab {
        ::v-deep.card-header {
            background: #fffbf0;
            @include shadow;

            .card-header-title {
                color: #427a0a;
            }
        }
    }
</style>
