<template>
    <component
        :is="tag"
        :class="type">
        <slot
            :value="value">
            <span>
                <slot
                    :value="value"
                    name="icon">
                    <b-icon
                        v-if="value > 0"
                        :class="{ 'has-text-success': colorful }"
                        icon="caret-up"></b-icon>
                    <b-icon
                        v-else-if="value < 0"
                        :class="{ 'has-text-danger': colorful }"
                        icon="caret-down"></b-icon>
                    <b-icon
                        v-else
                        icon="minus"
                        size="is-small"></b-icon>
                </slot>
            </span>
            <slot
                :value="value"
                name="value">
                {{ value }}
            </slot>
        </slot>
    </component>
</template>

<script>
  export default {
    name: "DifferenceIndicator",
    props: {
      value: {
        type: Number,
        required: true
      },
  
      icons: {
        type: Array,
        default: null
      },
  
      colorful: {
        type: Boolean,
        default: true
      },

      tag: {
        type: String,
        default: "small"
      },

      type: {
        type: String,
        default: null
      }
    }
  };
</script>

<style scoped>

</style>
