<template>
    <div class="columns is-multiline is-real-mobile-flex">
        <FilterWrapper>
            <b-field :label="$t(`admin.dashboard.filters.labels.withdrawType`)">
                <ExcludeSelect
                    :loading="loading"
                    name="withdrawType"
                    :included-filters.sync="withdrawType"
                    :excluded-filters.sync="excludedWithdrawType"
                    :searchable="false"
                    :get-data="getWithdrawTypes"
                    :seed="currentVertical"
                    :placeholder="$t(`admin.dashboard.filters.values.all`)"
                    field="label"
                    prop="value">
                </ExcludeSelect>
            </b-field>
        </FilterWrapper>

        <AccessControlFilter
            type="manager"
            name="managerId"
            :loading="loading"
            :placeholder="$t(`common.entity.filters.all`)"
            :included-filters.sync="managerId"
            :excluded-filters.sync="excludedManagerId">
        </AccessControlFilter>

        <AccessControlFilter
            type="webmaster"
            name="webmasterId"
            :loading="loading"
            :placeholder="$t(`common.entity.filters.all`)"
            :included-filters.sync="webmasterId"
            :excluded-filters.sync="excludedWebmasterId">
        </AccessControlFilter>

        <AccessControlFilter
            type="offer"
            name="offerId"
            vertical
            :loading="loading"
            :placeholder="$t(`common.entity.filters.all`)"
            :included-filters.sync="offerId"
            :excluded-filters.sync="excludedOfferId">
        </AccessControlFilter>

        <AccessControlFilter
            type="advertiser"
            name="advertiserId"
            vertical
            :loading="loading"
            :placeholder="$t(`common.entity.filters.all`)"
            :included-filters.sync="advertiserId"
            :excluded-filters.sync="excludedAdvertiserId">
        </AccessControlFilter>

        <FilterWrapper>
            <b-field :label="$t(`admin.statistics.statistics.filters.labels.offerType`)">
                <Select
                    v-model="isInHouse"
                    :get-data="getOfferType"
                    class="white"
                    :placeholder="$t(`admin.dashboard.filters.values.all`)"
                    :searchable="false"
                    field="label"
                    prop="value">
                    <template #text="{ option }">
                        <span v-t="option.label"></span>
                    </template>
                    <template #selected-option="{ option }">
                        <span v-t="option.label"></span>
                    </template>
                </Select>
            </b-field>
        </FilterWrapper>

        <FilterWrapper>
            <b-field :label="$t(`admin.statistics.statistics.filters.labels.countries`)">
                <ExcludeSelect
                    :loading="loading"
                    name="countryId"
                    :included-filters.sync="countryId"
                    :excluded-filters.sync="excludedCountryId">
                    <template #default="{ value, exclude }">
                        <CountriesSelect
                            :value="value"
                            multiple
                            :placeholder="$t(`admin.dashboard.filters.values.all`)"
                            @input="exclude ? excludedCountryId = $event : countryId = $event">
                        </CountriesSelect>
                    </template>
                </ExcludeSelect>
            </b-field>
        </FilterWrapper>
    </div>
</template>

<script>
  import dashboardStatisticFilters from "@/components/Admin/Dashboard/datasets/dashboardStatisticFilters.json";
  import AccessControlFilter from "@/components/Admin/Select/AccessControlFilter.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import ExcludeSelect from "@/components/Common/Select/ExcludeSelect.vue";
  import CountriesSelect from "@/components/Common/Select/CountriesSelect.vue";
  import {
    GET_WITHDRAW_TYPES
  } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import {
    UPDATE_EXCLUDED_FILTERS,
    UPDATE_FILTERS
  } from "@core/store/mutation-constants";

  export default {
    name: "DashboardFiltersOptions",
    components: {
      Select,
      ExcludeSelect,
      CountriesSelect,
      AccessControlFilter
    },

    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapFields("filtersConfirm", {
        fields: dashboardStatisticFilters.advancedFilters,
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapFields("filtersConfirm", {
        fields: dashboardStatisticFilters.advancedExcludedFilters,
        base: "excludedFilters",
        action: UPDATE_EXCLUDED_FILTERS
      }),

      ...mapState("admin/dashboard", {
        withdrawTypesList: state => state.withdrawTypesList.data
      }),

      ...mapState("verticals", {
        currentVertical: ({ currentVertical }) => currentVertical
      })
    },

    methods: {
      ...mapActions("admin/dashboard", {
        GET_WITHDRAW_TYPES: `withdrawTypesList/${ GET_WITHDRAW_TYPES }`
      }),

      getWithdrawTypes () {
        this.GET_WITHDRAW_TYPES();
        return this.withdrawTypesList;
      },

      getOfferType () {
        const items = [
          {
            value: true,
            label: this.$t("admin.statistics.statistics.filters.values.type.true")
          },
          {
            value: false,
            label: this.$t("admin.statistics.statistics.filters.values.type.false")
          }
        ];
        return {
          items,
          count: items.length
        };
      }
    }
  };
</script>

<style scoped>

</style>
