<template>
    <SectionWrapper>
        <template
            v-if="role !== 'advertiser'"
            #verticals>
            <VerticalsFilter :show-common="role === 'admin'"></VerticalsFilter>
        </template>
        <Role>
            <template #webmaster>
                <DashboardWebmaster></DashboardWebmaster>
            </template>
            <template #advertiser>
                <DashboardAdvertiser></DashboardAdvertiser>
            </template>
            <template #admin>
                <DashboardAdmin></DashboardAdmin>
            </template>
        </Role>
    </SectionWrapper>
</template>

<script>
  import DashboardAdmin from "@/components/Admin/Dashboard/Dashboard";
  import VerticalsFilter from "@/components/Common/Fields/VerticalsFilter.vue";
  import DashboardAdvertiser from "@/components/Advertiser/Dashboard/Dashboard";
  import DashboardWebmaster from "@/components/Webmaster/Dashboard/Dashboard";
  import SectionWrapper from "@/components/Common/SectionWrapper";
  import Role from "@/components/Common/Role";
  import { TELEGRAM_CONNECT } from "@core/store/action-constants";
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: "Dashboard",
    components: {
      VerticalsFilter,
      Role,
      SectionWrapper,
      DashboardAdmin,
      DashboardWebmaster,
      DashboardAdvertiser
    },
    async created () {
      if (sessionStorage.getItem("telegram_connect")) {
        let result;
        try {
          result = await this.TELEGRAM_CONNECT();
          if (!result) {
            this.$buefy.dialog.alert({
              title: " ",
              type: "is-danger",
              message: this.$t("common.telegramConnect.messages.error")
            }
            );
          }
        } catch (e) {
          this.$buefy.dialog.alert({
            title: " ",
            type: "is-danger",
            message: this.$t("common.telegramConnect.messages.USER_TELEGRAM_IS_NOT_SAME")
          });
        }
      }
    },

    computed: {
      ...mapGetters(["role"])
    },
  
    methods: {
      ...mapActions("telegram", [
        TELEGRAM_CONNECT
      ])
    }
  };
</script>
