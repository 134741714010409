<template>
    <div>
        <b-notification
            v-if="isNotice"
            :closable="false"
            type="is-danger">
            <i18n path="common.notice">
                <template #linebreak>
                    <br>
                </template>
            </i18n>
        </b-notification>

        <CompetitionRatingBanner
            v-if="isVisibleCompetition"
            :place="GET_COMPETITION_PLACE">
        </CompetitionRatingBanner>

        <!--        <Competition></Competition>-->

        <div class="columns is-multiline">
            <div
                class="column"
                :class="responsiveCards">
                <Card>
                    <div class="columns is-vcentered">
                        <div class="column is-narrow">
                            <!--suppress HtmlUnknownTarget -->
                            <img
                                :alt="$t(`webmaster.dashboard.filters.labels.leads.${ currentVertical }`)"
                                class="image is-64x64"
                                src="icons/Dashboard/Webmaster/dayStatLeads.svg">
                        </div>
                        <div class="column">
                            <div class="level is-mobile">
                                <div class="level-left flex is-flex-direction-column is-align-items-start">
                                    <h5 class="subtitle has-text-left is-5 m-0">
                                        {{ $t(`webmaster.dashboard.filters.labels.leads.${ currentVertical }`) }}
                                    </h5>
                                    <span
                                        class="has-text-grey"
                                        style="font-size: 14px">
                                        {{ $t("webmaster.dashboard.filters.labels.today") }}
                                    </span>
                                </div>
                                <div class="level-right">
                                    <div
                                        v-if="isLoadingDayStatistic"
                                        style="margin: 13px 0; width: 80%">
                                        <b-skeleton
                                            size="is-large"
                                            width="100%"
                                            height="49px">
                                        </b-skeleton>
                                    </div>
                                    <h3
                                        v-else
                                        class="subtitle is-flex is-flex-direction-column is-align-items-end has-text-left is-5">
                                        <span
                                            :key="leads.currency"
                                            v-for="leads of dayStats.leads"
                                            class="nowrap">
                                            {{ defaultFormatter(leads.value) }} ({{ currencySign(leads.currency) }})
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

            <div
                class="column"
                :class="responsiveCards">
                <Card>
                    <div class="columns is-vcentered">
                        <div class="column is-narrow">
                            <!--suppress HtmlUnknownTarget -->
                            <img
                                :alt="$t(`webmaster.dashboard.filters.labels.confirmed.${ currentVertical }`)"
                                class="image is-64x64"
                                src="icons/Dashboard/Webmaster/dayStatConfirmed.svg">
                        </div>
                        <div class="column">
                            <div class="level is-mobile">
                                <div class="level-left flex is-flex-direction-column is-align-items-start">
                                    <h5 class="subtitle has-text-left is-5 m-0">
                                        {{ $t(`webmaster.dashboard.filters.labels.confirmed.${ currentVertical }`) }}
                                    </h5>
                                    <span
                                        class="has-text-grey"
                                        style="font-size: 14px">
                                        {{ $t("webmaster.dashboard.filters.labels.today") }}
                                    </span>
                                </div>
                                <div class="level-right">
                                    <div
                                        v-if="isLoadingDayStatistic"
                                        style="margin: 13px 0; width: 80%">
                                        <b-skeleton
                                            size="is-large"
                                            width="100%"
                                            height="49px">
                                        </b-skeleton>
                                    </div>
                                    <h5
                                        v-else
                                        class="subtitle is-flex is-flex-direction-column is-align-items-end has-text-left is-5">
                                        <span
                                            :key="confirmed.currency"
                                            v-for="confirmed of dayStats.confirmed"
                                            class="nowrap">
                                            {{ defaultFormatter(confirmed.value) }} ({{ currencySign(confirmed.currency) }})
                                        </span>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

            <div
                class="column"
                :class="responsiveCards">
                <Card>
                    <div class="columns is-vcentered">
                        <div class="column is-narrow">
                            <!--suppress HtmlUnknownTarget -->
                            <img
                                :alt="$t(`webmaster.dashboard.filters.labels.income`)"
                                class="image is-64x64"
                                src="icons/Dashboard/Webmaster/dayStatIncomes.svg">
                        </div>
                        <div class="column">
                            <div class="level is-mobile">
                                <div class="level-left flex is-flex-direction-column is-align-items-start">
                                    <h5 class="subtitle has-text-left is-5 m-0">
                                        {{ $t(`webmaster.dashboard.filters.labels.income`) }}
                                    </h5>
                                    <span
                                        class="has-text-grey"
                                        style="font-size: 14px">
                                        {{ $t("webmaster.dashboard.filters.labels.today") }}
                                    </span>
                                </div>
                                <div class="level-right">
                                    <div
                                        v-if="isLoadingDayStatistic"
                                        style="margin: 13px 0; width: 80%">
                                        <b-skeleton
                                            size="is-large"
                                            width="100%"
                                            height="49px">
                                        </b-skeleton>
                                    </div>
                                    <h3
                                        v-else
                                        class="subtitle is-flex is-flex-direction-column is-align-items-end has-text-left is-5">
                                        <span
                                            :key="index"
                                            v-for="(item, index) of dayStats.income"
                                            class="nowrap">
                                            {{ fixedCurrency(item) }}
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>

            <div
                v-if="isAgent"
                class="column"
                :class="responsiveCards">
                <Card>
                    <div class="columns is-vcentered">
                        <div class="column is-narrow">
                            <img
                                :alt="$t(`webmaster.dashboard.filters.labels.commission`)"
                                class="image is-64x64"
                                :src="commissionImg">
                        </div>
                        <div class="column">
                            <div class="level is-mobile">
                                <div class="level-left flex is-flex-direction-column is-align-items-start">
                                    <h5 class="subtitle has-text-left is-5 m-0">
                                        {{ $t(`webmaster.dashboard.filters.labels.commission`) }}
                                    </h5>
                                    <span
                                        class="has-text-grey"
                                        style="font-size: 14px">
                                        {{ $t("webmaster.dashboard.filters.labels.today") }}
                                    </span>
                                </div>
                                <div class="level-right">
                                    <div
                                        v-if="isLoadingDayStatistic"
                                        style="margin: 13px 0; width: 80%">
                                        <b-skeleton
                                            size="is-large"
                                            width="100%"
                                            height="49px">
                                        </b-skeleton>
                                    </div>
                                    <h3
                                        v-else
                                        class="subtitle is-flex is-flex-direction-column is-align-items-end has-text-left is-5">
                                        <span
                                            :key="item.currency"
                                            v-for="item of dayStats.commission"
                                            class="nowrap">
                                            {{ defaultFormatter(item.value) }} ({{ currencySign(item.currency) }})
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
        <div class="columns dashboardBody is-2 is-multiline">
            <div class="column chart">
                <ExpandFullscreen
                    class="chart-modal"
                    fixed-orientation
                    :available-orientations="['landscape-primary']"
                    start-orientation="landscape-primary">
                    <DashboardFilters>
                    </DashboardFilters>

                    <Card class="chart">
                        <DashboardChart>
                        </DashboardChart>
                    </Card>
                </ExpandFullscreen>
            </div>
            <div class="column offers">
                <BlockBox :title="$t(`webmaster.dashboard.topOffers.name`)">
                    <OffersTop></OffersTop>
                </BlockBox>
            </div>
        </div>
    </div>
</template>

<script>
  import {
    GET_COMPETITION_PLACE,
    GET_DAY_STATISTIC,
    GET_STATISTIC,
    GET_TOP_OFFERS,
    SET_EMPTY, UPDATE_OPTIONS
  } from "@core/store/action-constants";
  import { mapActions, mapGetters, mapState } from "vuex";
  import BlockBox from "@/components/Common/BlockBox";
  import Card from "@/components/Common/Card";
  import DashboardChart from "@/components/Webmaster/Dashboard/DashboardChart";
  import DashboardFilters from "@/components/Webmaster/Dashboard/DashboardFilters";
  import OffersTop from "@/components/Webmaster/Dashboard/OffersTop";
  // import Competition from "@/components/Webmaster/Dashboard/Competition/Competition";
  import commission from "@/assets/Dashboard/commission.svg";
  import { currencySign, defaultFormatter } from "@core/filters";
  import { sortByPreferredCurrency } from "@core/helpers/sortByPreferredCurrency";
  import { fixedCurrency } from "@core/flowMethods";
  import ExpandFullscreen from "@/components/Common/Chart/ExpandFullscreen";
  import CompetitionRatingBanner from "@/components/Common/Competition/CompetitionRatingBanner.vue";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";

  export default {
    name: "Dashboard",

    components: {
      CompetitionRatingBanner,
      ExpandFullscreen,
      DashboardFilters,
      DashboardChart,
      // Competition,
      BlockBox,
      OffersTop,
      Card
    },

    created () {
      this.GET_DAY_STATISTIC();
    },

    computed: {
      ...mapState("webmaster/dashboard/statistic", {
        dayStats: "todayStatistic"
      }),

      ...mapState({
        subType: ({ auth }) => auth.subType,
        commissionList: state => state.balance?.detailed
      }),

      ...mapState("verticals", {
        currentVertical: ({ currentVertical }) => currentVertical
      }),

      ...mapGetters("competition", [
        GET_COMPETITION_PLACE,
        "isVisibleCompetition"
      ]),

      ...mapGetters(["isAgent"]),

      commissionImg () {
        return commission;
      },
      isNotice () {
        return process.env.VUE_APP_NOTICE_ON === "true";
      },

      responsiveCards () {
        return `is-${ 4 - this.isAgent }-real-fullhd is-4-widescreen is-6`;
      },

      sortedCommissionList () {
        return sortByPreferredCurrency(this.commissionList?.map(item => item.balance));
      },

      isLoadingDayStatistic () {
        return this.$wait(`webmaster/dashboard/statistic/${ GET_DAY_STATISTIC }`);
      }
    },

    methods: {
      defaultFormatter,
      ...mapActions("webmaster/dashboard/statistic", [
        GET_DAY_STATISTIC,
        GET_STATISTIC,
        SET_EMPTY
      ]),

      ...mapActions("webmaster/offers", { GET_TOP_OFFERS }),

      currencySign,
      fixedCurrency
    },

    watch: {
      currentVertical () {
        this.GET_DAY_STATISTIC();
        this.GET_STATISTIC();
        this.GET_TOP_OFFERS();
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    .level {
        .level-left, .level-right {
            flex-grow: 1;
            flex-shrink: 1;
        }
    }

    .dashboard-actions__radio-cover {
        margin-left: 10px;
    }

    .dashboardBody {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas:
                "banner"
                "chart"
                "offers";
    }

    .chart {
        grid-area: chart;
    }

    .banner {
        grid-area: banner;
    }

    .offers {
        grid-area: offers;
    }

    //noinspection SassScssUnresolvedMixin
    @include fullhd {
        .dashboardBody {
            grid-template-columns: 66.75% 33.25%;
            grid-template-areas:
                "chart offers";
        }
    }
</style>
