<template>
    <div :class="['rating-banner', { webmaster: isWebmasterRole }]">
        <div class="rating-banner_wrapper-info">
            <div class="rating-banner_wrapper-title">
                <div class="rating-banner_title">
                    <span ref="firstTitleElement">
                        Br
                    </span>
                    eaking
                </div>
                <div
                    class="rating-banner_title"
                    :style="{ 'margin-left': `${ titleElementWidth }px`}">
                    <span>
                        Ba
                    </span>
                    d
                </div>
            </div>
            <div class="rating-banner_info">
                <div class="rating-banner_subtitle">
                    <img
                        alt="logo"
                        src="@/assets/Competition/Raffle/logo.svg">
                    <span class="pre-wrap">
                        {{ $t('common.rating.banner.subtitle') }}
                    </span>
                </div>
                <template v-if="isWebmasterRole">
                    <div class="rating-banner_button button-point">
                        <span class="pre-wrap">
                            {{ $t("common.rating.banner.buttons.point") }}
                        </span>
                        <div class="rating-banner_button-info">
                            {{ formatEmptyString(currentCompetition && currentCompetition.balance) }}
                        </div>
                    </div>
                    <div class="rating-banner_button button-place">
                        <span class="pre-wrap">
                            {{ $t("common.rating.banner.buttons.place") }}
                        </span>
                        <div class="rating-banner_button-info">
                            {{ formatEmptyString(currentCompetition && currentCompetition.place) }}
                        </div>
                    </div>
                </template>
                <template v-if="isWebmasterRole && hasDashboard">
                    <router-link
                        :to="{
                            name: 'webmaster:competitionRating',
                            query: {
                                backLink: $route.fullPath,
                                filters: encodeFilters({ inCompetition: true })
                            }
                        }"
                        class="rating-banner_button tournament-table">
                        <img
                            alt="tournament-table"
                            class="mr-3"
                            src="@/assets/Competition/Raffle/Tournament-Table.svg">
                        <span>
                            {{ $t("common.rating.banner.buttons.tournamentTable") }}
                        </span>
                    </router-link>
                </template>
            </div>

            <CompetitionRatingDate></CompetitionRatingDate>
        </div>

        <CompetitionRatingPrizesCard></CompetitionRatingPrizesCard>
    </div>
</template>

<script>
  import CompetitionRatingPrizesCard from "@/components/Common/Competition/CompetitionRatingPrizesCard.vue";
  import CompetitionRatingDate from "@/components/Common/Competition/CompetitionRatingDate.vue";
  import { formatEmptyString } from "@/filters";
  import { mapGetters, mapState } from "vuex";
  import { encodeFilters } from "@core/filters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_OPTIONS } from "@core/store/action-constants";

  export default {
    name: "CompetitionRatingBanner",

    components: {
      CompetitionRatingPrizesCard,
      CompetitionRatingDate
    },

    props: {
      place: {
        type: Number,
        default: null
      }
    },

    mounted () {
      window.addEventListener("resize", this.setMarginLeft);
      this.setMarginLeft();
    },

    data () {
      return {
        titleElementWidth: 96
      };
    },

    computed: {
      ...mapGetters(["role"]),

      ...mapState("competition", {
        currentCompetition: ({ currentCompetition }) => currentCompetition
      }),

      ...mapFields("competition", {
        fields: ["isActive"],
        base: "options",
        action: UPDATE_OPTIONS
      }),

      isWebmasterRole () {
        return this.role === "webmaster";
      },

      hasDashboard () {
        return this.$route.name === "dashboard";
      }
    },

    methods: {
      encodeFilters,
      formatEmptyString,

      setMarginLeft () {
        this.titleElementWidth = this.$refs.firstTitleElement?.offsetWidth;
      }
    },

    destroyed () {
      window.removeEventListener("resize", this.setMarginLeft);
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style scoped lang="scss">
  @import "~@/scss/competition-banner";

  .columns:not(:last-child) {
    margin-bottom: -0.75rem !important;
  }

  .pre-wrap {
    white-space: pre;
  }

  .rating-banner {
    $self: &;

    #{$self}_wrapper-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    #{$self}_wrapper-title {
      margin-bottom: 8px;
    }

    #{$self}_title:nth-child(2) {
      span {
        padding: 10px 4px;

        &::before {
          content: "56";
        }
      }
    }

    #{$self}_title {
      display: flex;
      align-items: center;

      letter-spacing: -0.04em;
      line-height: 1;
      color: white;
      font-size: clamp(33px, 3.4vw, 68px);
      font-weight: 800;

      @media screen and (max-width: 720px) {
        font-size: clamp(24px, 6.6vw, 48px);
      }

      span {
        position: relative;
        padding: 10px 9px;
        margin-right: 4px;

        box-shadow: 0 0 24px 0 rgba(191, 255, 84, 0.31);
        background: linear-gradient(134deg, #2e4a07 0%, #719d34 100%);
        border: clamp(2px, 0.7vw, 4px) solid white;

        @media screen and (max-width: 720px) {
          padding: clamp(4px, 1.2vw, 10px) !important;
        }

        &::before {
          content: "35";
          position: absolute;
          top: 5px;
          right: 3px;

          font-weight: 500;
          font-size: 13px;
          letter-spacing: -0.05em;

          @media screen and (max-width: 720px) {
            font-size: clamp(6px, 1.5vw, 11px);
          }
        }
      }
    }

    #{$self}_info {
      display: flex;
    }

    .button-place {
      #{$self}_button-info {
        background-color: #EBC81A;
        background-image: none;
      }
    }

    #{$self}_button {
      display: flex;
      align-items: center;

      padding: 4px;
      margin-left: 8px;

      border-radius: 32px;
      background-color: black;

      span {
        margin-left: clamp(8px, 0.65vw, 12px);
        margin-right: clamp(10px, 0.65vw, 16px);

        font-weight: 500;
        font-size: clamp(9px, 0.65vw, 13px);
        line-height: 1.38;
        letter-spacing: -0.04em;
      }

      &-info {
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 20px;
        padding: 4px 10px;

        min-width: clamp(53px, 4.65vw, 96px);
        height: 100%;

        color: black;
        font-weight: 800;
        font-size: clamp(13px, 1.4vw, 22px);
        line-height: 1.27;
        letter-spacing: -0.05em;

        background-color: $color-green;
        background-image: url("~@/assets/Competition/Raffle/Crystal-Button.svg");
        background-size: cover;
      }
    }

    .tournament-table {
      position: relative;
      z-index: 10;

      padding: 4px 21px;
      color: white;

      @media screen and (max-width: 720px) {
        height: fit-content;
        padding: 8px 20px;
        margin-top: auto;

        & img {
          margin-right: 0 !important;
        }
      }

      span {
        font-weight: 500;
        font-size: clamp(10px, 0.85vw, 16px);
        line-height: 1.5;
        letter-spacing: -0.03em;

        @media screen and (max-width: 720px) {
          font-size: clamp(10px, 2.2vw, 16px);
        }
      }
    }

    #{$self}_subtitle {
      display: flex;
      margin-right: clamp(10px, 1vw, 20px);

      img {
        width: clamp(22px, 1.7vw, 32px);
        height: clamp(27px, 2.1vw, 40px);
        margin-right: clamp(10px, 0.9vw, 16px);

        @media screen and (max-width: 720px) {
          width: clamp(20px, 5.5vw, 40px);
          height: clamp(24px, 6.7vw, 48px);
        }
      }

      span {
        font-weight: 800;
        line-height: 1.25;
        letter-spacing: -0.03em;
        font-size: clamp(11px, 0.83vw, 16px);

        @media screen and (max-width: 720px) {
          font-weight: 500;
          font-size: clamp(11px, 3vw, 21px);
          letter-spacing: -0.05em;
        }
      }
    }

    @media screen and (max-width: 720px) {
      .button-place, .button-point {
        display: none;
      }
    }
  }
</style>
