<template>
    <section class="chart-filters">
        <LFilters
            ref="filters"
            class="mt-6"
            :filters="filters"
            :excluded-filters="excludedFilters"
            :advanced-filters="advancedFilters"
            :loading="loading"
            @clear="SET_EMPTY('dashboardFilters')"
            @confirm="confirmFilters">
            <template #top-left>
                <FilterWrapper>
                    <b-field :label="$t(`admin.dashboard.filters.labels.groups`)">
                        <Select
                            v-model="groups"
                            :clearable="false"
                            :get-data="getGroupingTypes"
                            :searchable="false"
                            class="white"
                            field="label"
                            prop="value">
                        </Select>
                    </b-field>
                </FilterWrapper>
            </template>

            <template #filters>
                <div class="columns is-multiline is-real-mobile-flex">
                    <FilterWrapper>
                        <CustomDatepicker v-model="datepicker"></CustomDatepicker>
                    </FilterWrapper>

                    <div class="column is-narrow">
                        <!-- eslint-disable-next-line no-irregular-whitespace -->
                        <b-field label=" ">
                            <CurrencyCheckbox v-model="currency"></CurrencyCheckbox>
                        </b-field>
                    </div>

                    <div class="column is-narrow">
                        <b-field>
                            <CustomCheckbox
                                v-model="withBlockedWebmaster"
                                switchable
                                :label="$t('admin.dashboard.filters.labels.withBlockedWebmaster')">
                            </CustomCheckbox>
                        </b-field>
                    </div>
                </div>
            </template>

            <template #advanced-filters>
                <DashboardFiltersOptions :loading="loading"></DashboardFiltersOptions>
            </template>
        </LFilters>
    </section>
</template>

<script>
  import DashboardFiltersOptions from "@/components/Admin/Dashboard/DashboardFiltersOptions";
  import LFilters from "@/components/Common/LFilters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import {
    UPDATE_FILTERS,
    UPDATE_DASHBOARD_STATISTIC_FILTERS,
    UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS
  } from "@core/store/mutation-constants";
  import {
    GET_DASHBOARD_STATISTICS,
    SET_EMPTY
  } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import CurrencyCheckbox from "@/components/Common/Controls/CurrencyCheckbox.vue";
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker.vue";
  import CustomCheckbox from "@/components/Common/Controls/CustomCheckbox.vue";
  import Select from "@/components/Common/Select/Select.vue";
  import dashboardStatisticFilters from "@/components/Admin/Dashboard/datasets/dashboardStatisticFilters.json";

  export default {
    name: "DashboardFilters",
    components: {
      DashboardFiltersOptions,
      CurrencyCheckbox,
      CustomDatepicker,
      CustomCheckbox,
      LFilters,
      Select
    },

    props: {
      filters: {
        type: Object,
        default: null
      },
      excludedFilters: {
        type: Object,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      ...mapFields("filtersConfirm", {
        fields: ["datepicker", "withBlockedWebmaster", "currency", "groups"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapState("verticals", {
        currentVertical: ({ currentVertical }) => currentVertical
      }),

      advancedFilters () {
        return [
          ...dashboardStatisticFilters.advancedFilters,
          ...dashboardStatisticFilters.advancedExcludedFilters
        ];
      }
    },

    methods: {
      ...mapActions("admin/dashboard/dashboardStatistic", {
        UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS,
        UPDATE_DASHBOARD_STATISTIC_FILTERS,
        GET_DASHBOARD_STATISTICS,
        SET_EMPTY
      }),

      confirmFilters ({ filters, excludedFilters }) {
        this.UPDATE_DASHBOARD_STATISTIC_FILTERS(filters);
        this.UPDATE_DASHBOARD_STATISTIC_EXCLUDED_FILTERS(excludedFilters);
        this.GET_DASHBOARD_STATISTICS();
      },

      getGroupingTypes () {
        const groups = [
          { value: "day", label: this.$t("common.groupsList.day") },
          { value: "week", label: this.$t("common.groupsList.week") },
          { value: "month", label: this.$t("common.groupsList.month") },
          { value: "year", label: this.$t("common.groupsList.year") }
        ];
        return {
          items: groups,
          count: groups.length
        };
      }
    },

    watch: {
      currentVertical () {
        this.$refs.filters.clear();
        this.GET_DASHBOARD_STATISTICS();
      }
    }
  };
</script>

<style lang="scss" scoped>
    section {
        margin-bottom: 1.5em;

        .columns {
            align-items: flex-end;
        }
    }
</style>
