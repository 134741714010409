<template>
    <div class="is-relative">
        <ul v-if="offers && offers.items.length">
            <li
                :key="i"
                v-for="(offer, i) in offers.items"
                class="is-clickable">
                <router-link
                    class="table-item--link"
                    :to="{ name: 'webmaster:offer', params: {
                        slug: offer.slug,
                        id: offer.id,
                        backLink: $route.fullPath
                    } }">
                    <div class="level is-mobile">
                        <div class="level-left">
                            <OfferLogo
                                :alt="offer.detail.name"
                                :image="offer.logo"
                                :vertical="offer.vertical"
                                size="is-48x48">
                            </OfferLogo>
                            <a href=""></a>
                            <span>{{ offer.detail.name }}</span>
                        </div>
                        <div class="level-right">
                            <span class="price has-text-weight-bold">{{ price(offer) }}</span>
                        </div>
                    </div>
                </router-link>
            </li>
        </ul>
        <div
            v-else
            class="hero has-text-centered">
            <h2 class="is-size-5 is-bold has-text-weight-medium has-text-grey">
                {{ $t(`common.table.empty`) }}
            </h2>
        </div>
        <LLoading
            :active="isLoading"
            :is-full-page="false">
        </LLoading>
    </div>
</template>

<script>
  import { mapActions, mapState } from "vuex";
  import { GET_TOP_OFFERS, SET_EMPTY } from "@core/store/action-constants";
  import OfferLogo from "@/components/Common/Output/OfferLogo.vue";
  import LLoading from "@/components/Common/LLoading";
  import { formatCurrency } from "@core/filters";

  export default {
    name: "OffersTop",
    components: { LLoading, OfferLogo },

    created () {
      this.GET_TOP_OFFERS();
    },

    computed: {
      ...mapState("webmaster/offers", {
        offers: ({ dashboardTopOffers }) => dashboardTopOffers
      }),

      isLoading () {
        return this.$wait(`webmaster/offers/${ GET_TOP_OFFERS }`);
      }
    },

    methods: {
      ...mapActions("webmaster/offers", {
        GET_TOP_OFFERS,
        SET_EMPTY
      }),

      price (offer) {
        const { value, currency } = offer.finance.webmasterReward;
        return formatCurrency(value, currency);
      }
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    div {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                padding: 0.5rem 0;

                &:not(:last-child) {
                    border-bottom: 1px solid #eaeaea;
                }

                &::v-deep {
                    figure {
                        overflow: hidden;
                        margin-right: 1em;
                        border-radius: 6px;
                        border: 1px solid #ececec;
                    }
                }

                .price {
                    font-size: smaller;
                }

                .level-left {
                    flex-shrink: 1;

                    span {
                        word-break: break-word;
                    }
                }
            }
        }
    }
</style>
