











































import Card from "@/components/Common/Card";
import { defineProps } from "@vue/runtime-dom";
import { useStatistic } from "@/stores/admin/statistics/statisticStore";
import { useWait } from "@/stores/common/waitStore";
const __sfc_main = {};
__sfc_main.props = {
  data: {
    type: Object,
    default: null
  },
  type: {
    type: String,
    default: null
  },
  title: {
    type: String,
    default: ""
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const actionName = `GET_${props.type.toUpperCase()}_COMPARE`;
  const store = useStatistic();
  const isLoading = useWait(store, actionName);

  // @ts-ignore
  store[actionName]();
  return {
    isLoading
  };
};
__sfc_main.components = Object.assign({
  Card
}, __sfc_main.components);
export default __sfc_main;
