<template>
    <CommonChartTemplate
        full-screen-mode
        :datasets="datasets"
        :custom-config="customConfig"
        :loading="isLoading"
        :tooltip-formatter="tooltipFormatter"
        :legend-options="legendOptions"
        :x-labels="xLabels"
        @legendselectchanged="controlSelected">
    </CommonChartTemplate>
</template>

<script>
  import { tooltipBuilder } from "@/components/Charts/tooltipBuilder";
  import CommonChartTemplate from "@/components/Common/Chart/CommonChartTemplate.vue";
  import { formatCurrency, formatPercent, moment, defaultFormatter } from "@core/filters";
  import { chartColors } from "@/components/Charts/chart.config";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import { mapState } from "vuex";

  export default {
    name: "DashboardChart",

    components: { CommonChartTemplate },

    data () {
      return {
        selected: {}
      };
    },

    computed: {
      ...mapState("webmaster/dashboard/statistic", {
        data: ({ chart }) => {
          const items = chart?.items;

          return items ? [...items].reverse() : null;
        },
        currency: ({ filters }) => filters.currency
      }),

      ...mapState("verticals", {
        currentVertical: ({ currentVertical }) => currentVertical
      }),

      isLoading () {
        return this.$wait(`webmaster/dashboard/statistic/${ GET_STATISTIC }`);
      },

      customConfig () {
        return {
          legend: {
            show: true
          }
        };
      },

      legendOptions () {
        return {
          selected: this.selected
        };
      },

      approve () {
        return this.data?.map(item => item.conversionApprove);
      },

      xLabels () {
        return this.data?.map((item) => moment(item.day, "YYYY-MM-DD")) ?? [];
      },

      datasets () {
        if (!this.data) return [];

        const { labels } = this.tooltip;

        const items = [
          {
            name: "leads",
            label: labels.leads,
            color: chartColors.default.info,
            backgroundColor: chartColors.default.infoFill,
            data: this.data.map((item) => item.countLeadsWhite)
          },
          {
            name: "approve",
            label: labels.approve,
            color: chartColors.default.warning,
            backgroundColor: chartColors.default.warningFill,
            data: this.data.map((item) => item.conversionCleanApprove)
          },
          {
            name: "income",
            label: labels.income,
            color: chartColors.default.success,
            backgroundColor: chartColors.default.successFill,
            data: this.data.map((item) => item.moneyWebmasterAccrued)
          }
        ];

        const dataSet = {
          GAMBLING: items,
          NUTRA: [
            ...items,
            {
              name: "validLeads",
              label: labels.validLeads,
              color: chartColors.default.danger,
              backgroundColor: chartColors.default.dangerFill,
              data: this.data.map((item) => item.countLeadsWhite - item.countLeadsSpamByAdvertiser)
            }
          ]
        };

        return dataSet[this.currentVertical] ?? dataSet.default;
      },

      tooltip () {
        return {
          labels: {
            leads: this.$t(`webmaster.dashboard.table.labels.leads.${ this.currentVertical }`),
            approve: this.$t(`webmaster.dashboard.table.labels.approve.${ this.currentVertical }`),
            income: this.$t("webmaster.dashboard.table.labels.earned"),
            validLeads: this.$t("webmaster.dashboard.table.labels.validLeads")
          },
          formatters: {
            leads: (value) => Math.floor(parseFloat(value)),
            approve: (value) => `${ defaultFormatter(formatPercent(value), 2) } %`,
            income: (value) => formatCurrency(parseFloat(value), this.currency)
          }
        };
      }
    },

    methods: {
      tooltipFormatter (seriesName, pinSeriesName) {
        const { labels, formatters } = this.tooltip;
        return (params) => tooltipBuilder(params, labels, formatters, seriesName, pinSeriesName);
      },

      controlSelected ({ selected }) {
        this.selected = selected;
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
