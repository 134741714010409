<template>
    <ExpandFullscreen
        class="chart-modal"
        fixed-orientation
        :available-orientations="['landscape-primary']"
        start-orientation="landscape-primary">
        <DashboardFilters></DashboardFilters>
        <DashboardChart></DashboardChart>
    </ExpandFullscreen>
</template>

<script>
  import DashboardFilters from "@/components/Advertiser/Dashboard/DashboardFilters";
  import DashboardChart from "@/components/Advertiser/Dashboard/DashboardChart";
  import ExpandFullscreen from "@/components/Common/Chart/ExpandFullscreen";
  import { SET_EMPTY } from "@core/store/mutation-constants";
  import { mapActions } from "vuex";

  export default {
    name: "Dashboard",

    components: {
      ExpandFullscreen,
      DashboardFilters,
      DashboardChart
    },

    methods: {
      ...mapActions("advertiser/dashboard", [SET_EMPTY])
    },

    beforeDestroy () {
      this.SET_EMPTY();
    }
  };
</script>

<style lang="scss" scoped>
    .level-right {
        white-space: nowrap;
    }
</style>
