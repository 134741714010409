<template>
    <Card>
        <CommonChartTemplate
            full-screen-mode
            :datasets="datasets"
            :custom-config="customConfig"
            :loading="isLoading"
            :tooltip-formatter="tooltipFormatter"
            :legend-options="legendOptions"
            :x-labels="xLabels"
            @legendselectchanged="controlSelected">
        </CommonChartTemplate>
    </Card>
</template>

<script>
  import Card from "@/components/Common/Card";
  import { tooltipBuilder } from "@/components/Charts/tooltipBuilder";
  import { formatPercent, moment, defaultFormatter } from "@core/filters";
  import { chartColors } from "@/components/Charts/chart.config";
  import { GET_STATISTIC } from "@core/store/action-constants";
  import { mapState } from "vuex";
  import CommonChartTemplate from "@/components/Common/Chart/CommonChartTemplate.vue";

  export default {
    name: "DashboardChart",
    components: { CommonChartTemplate, Card },

    data () {
      return {
        selected: {}
      };
    },

    computed: {
      ...mapState("advertiser/dashboard", {
        activeChart: ({ options }) => options.activeChart,
        data: ({ chart }) => chart?.items ? [...chart.items].reverse() : null
      }),

      ...mapState("verticals", {
        currentVertical: ({ currentVertical }) => currentVertical
      }),

      isLoading () {
        return this.$wait(`advertiser/dashboard/${ GET_STATISTIC }`);
      },

      customConfig () {
        return {
          legend: {
            show: false
          }
        };
      },

      legendOptions () {
        return {
          selected: this.selected
        };
      },

      approve () {
        return this.data?.map(item => item.conversionApprove);
      },

      xLabels () {
        return this.data?.map((item) => moment(item.day, "YYYY-MM-DD")) ?? [];
      },

      tooltip () {
        return {
          labels: {
            LEADS: this.$t(`advertiser.dashboard.table.labels.leads.${ this.currentVertical }`),
            APPROVE: this.$t(`advertiser.dashboard.table.labels.approve.${ this.currentVertical }`)
          },
          formatters: {
            LEADS: (value) => `${ defaultFormatter(Math.floor(value)) }`,
            APPROVE: (value) => `${ defaultFormatter(value, 2) } %`
          }
        };
      },

      datasets () {
        if (!this.data) return [];

        const { labels } = this.tooltip;

        const arr = [
          {
            name: "LEADS",
            label: labels.leads,
            color: chartColors.default.info,
            backgroundColor: chartColors.default.infoFill,
            data: this.data.map((item) => item.countLeadsWhite)
          },
          {
            name: "APPROVE",
            label: labels.approve,
            color: chartColors.default.warning,
            backgroundColor: chartColors.default.warningFill,
            data: this.data.map((item) => formatPercent(item.conversionCleanApprove)),
            isPercent: true
          }
        ];

        return arr.filter(({ name }) => name === this.activeChart);
      }
    },

    methods: {
      tooltipFormatter (seriesName, pinSeriesName) {
        const { labels, formatters } = this.tooltip;
        return (params) => tooltipBuilder(params, labels, formatters, seriesName, pinSeriesName);
      },

      controlSelected ({ selected }) {
        this.selected = selected;
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
