<template>
    <CommonChartTemplate
        :datasets="datasets"
        full-screen-mode
        :loading="loading"
        :legend-options="legendOptions"
        :tooltip-formatter="tooltipFormatter"
        :x-labels="xLabels"
        @legendselectchanged="controlSelected">
    </CommonChartTemplate>
</template>

<script>
  import { chartColors } from "@/components/Charts/chart.config";
  import { tooltipBuilder } from "@/components/Charts/tooltipBuilder";
  import CommonChartTemplate from "@/components/Common/Chart/CommonChartTemplate.vue";
  import { formatCurrency, moment, toFixed } from "@core/filters";
  import { hasPermissionsStrict } from "@core/mixins/permissions";
  import { mapState } from "vuex";
  import { VerticalsEnum } from "@core/store/types/admin/common/enums/VerticalsEnum";
  import { fixedFormatPercent } from "@core/flowMethods";
  import { useOffers } from "@/stores/admin/offers/offersStore";

  export default {
    name: "DashboardChart",
    components: { CommonChartTemplate },

    props: {
      data: {
        type: Object,
        default: null
      },
      filters: {
        type: Object,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        selected: {
          "leads": false,
          "cashFlow": false,
          "countExternalLeadsCreated": false
        }
      };
    },

    setup () {
      const { isShowCommissions } = useOffers();
      return { isShowCommissions };
    },

    computed: {
      ...mapState({
        filteredDatasetsFields ({ auth }) {
          return this.datasetsFields.filter(({ permissions }) => hasPermissionsStrict(permissions, auth.userPermissions));
        },
        currentVertical: ({ verticals }) => verticals.currentVertical
      }),

      legendOptions () {
        return {
          selected: this.selected
        };
      },

      approve () {
        return this.data.items.map(item => item.conversionApprove);
      },

      xLabels () {
        if (!this.data?.items) return [];

        const formats = {
          day: "DD MMM YYYY",
          week: "DD MMM YYYY",
          month: "MMM YYYY"
        };
        const groups = this.filters.groups;

        return this.data.items.map((item) => moment(item[groups], formats[groups]));
      },

      datasetsFields () {
        const { labels } = this.tooltip;

        return [
          this.currentVertical !== VerticalsEnum.GAMBLING && {
            name: "leads",
            label: labels.leads,
            color: chartColors.default.danger,
            backgroundColor: chartColors.default.dangerFill,
            data: this.data.items.map((item) => toFixed(item.countLeadsCreated)),
            permissions: ["SUPER.PERMISSION"]
          },
          {
            name: "countLeadsIntegrated",
            label: labels.countLeadsIntegrated,
            color: chartColors.default.violet,
            backgroundColor: chartColors.default.violetFill,
            data: this.data.items.map(item => toFixed(item.countLeadsIntegrated)),
            permissions: ["SUPER.PERMISSION"]
          },
          this.currentVertical !== VerticalsEnum.GAMBLING && {
            name: "countLeadsValid",
            label: labels.countLeadsValid,
            color: chartColors.default.info,
            backgroundColor: chartColors.default.infoFill,
            data: this.data.items.map(item => toFixed(item.countLeadsCreated - item.countLeadsSpammed)),
            permissions: ["SUPER.PERMISSION"]
          },
          {
            name: "leadsConfirmed",
            label: labels.leadsConfirmed,
            color: chartColors.default.warning,
            backgroundColor: chartColors.default.warningFill,
            data: this.data.items.map((item) => toFixed(item.countLeadsAccepted)),
            permissions: ["SUPER.PERMISSION"]
          },
          {
            name: "cashFlow",
            label: labels.cashFlow,
            color: chartColors.default.success,
            backgroundColor: chartColors.default.successFill,
            data: this.data.items.map(item => item.moneyAdvertiserWrittenOff),
            permissions: ["DASHBOARD.CASH_FLOW.GET"]
          },
          this.isShowCommissions && {
            name: "commission",
            label: labels.commission,
            color: chartColors.default.light,
            backgroundColor: chartColors.default.lightFill,
            data: this.data.items.map(item => item.moneyCommission),
            permissions: ["DASHBOARD.COMMISSION.GET"]
          },
          this.currentVertical !== VerticalsEnum.GAMBLING && {
            name: "countExternalLeadsCreated",
            label: labels.countExternalLeadsCreated,
            color: chartColors.default.violetLight,
            backgroundColor: chartColors.default.violetLightFill,
            data: this.data.items.map(item => toFixed(item.countExternalLeadsCreated)),
            permissions: ["SUPER.PERMISSION"]
          }
        ].filter(Boolean);
      },

      datasets () {
        if (!this.data?.items) return [];

        return this.filteredDatasetsFields.map(({ permissions, ...item }) => item);
      },

      tooltip () {
        return {
          labels: {
            leads: this.$t("admin.dashboard.filters.labels.chart.leadsData"),
            leadsConfirmed: this.$t(`admin.dashboard.filters.labels.chart.leadsConfirmedData.${ this.currentVertical }`),
            cashFlow: this.$t("admin.dashboard.filters.labels.chart.cashFlow"),
            commission: this.$t("admin.dashboard.filters.labels.chart.commissionData"),
            countLeadsIntegrated: this.$t(`admin.dashboard.filters.labels.chart.countLeadsIntegrated.${ this.currentVertical }`),
            countLeadsValid: this.$t("admin.dashboard.filters.labels.chart.countLeadsValid"),
            countExternalLeadsCreated: this.$t("admin.dashboard.filters.labels.chart.countExternalLeadsCreated")
          },
          formatters: {
            leadsConfirmed: (value, index) => {
              const approve = this.approve[index];
              const approvePercent = approve === 0 ? 0 : fixedFormatPercent(approve);

              return `${ value } (${ approvePercent } %)`;
            },
            commission: value => formatCurrency(value, this.filters.currency),
            cashFlow: value => formatCurrency(value, this.filters.currency)
          }
        };
      }
    },

    methods: {
      tooltipFormatter (seriesName, pinSeriesName) {
        const { labels, formatters } = this.tooltip;
        return (params) => tooltipBuilder(params, labels, formatters, seriesName, pinSeriesName);
      },

      controlSelected ({ selected }) {
        this.selected = selected;
      }
    }
  };
</script>

<style scoped lang="scss">
</style>
