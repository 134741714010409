












































import Permissions from "@/components/Common/Permissions.vue";
import DifferenceCard from "@/components/Admin/Dashboard/DifferenceCard.vue";
import DifferenceIndicator from "@/components/Admin/Dashboard/DifferenceIndicator.vue";
import { defaultFormatter, currencySign } from "@core/filters";
import { useStatistic } from "@/stores/admin/statistics/statisticStore";
import { storeToRefs } from "pinia";
import { computed, onBeforeUnmount } from "@vue/composition-api";
import { useVerticals } from "@/stores/common/auth/verticalsStore";
import { Money } from "@core/store/types/common";
import { statisticCard } from "@core/store/types/common/statistic/common/statistic";
import { fixedFormatPercent } from "@core/flowMethods";
import { useOffers } from "@/stores/admin/offers/offersStore";
interface DifferenceCards {
  type: string;
  data: statisticCard;
  permissions: string[];
  percents?: number;
}
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const {
    isShowCommissions
  } = useOffers();
  const store = useStatistic();
  const {
    cards,
    enabledStatistic
  } = storeToRefs(store);
  const verticalsStore = useVerticals();
  const {
    isVisibleByAllVerticals,
    currentVertical
  } = storeToRefs(verticalsStore);
  const dashboardDifferenceCards = computed<DifferenceCards[]>(() => [isVisibleByAllVerticals.value && {
    type: "leads",
    data: cards.value.leads,
    permissions: ["STATISTICS.VIEW", "STATISTICS.VIEW.WEBMASTERS.OWN"]
  }, {
    type: "approve",
    data: cards.value.approve,
    permissions: ["SUPER.PERMISSION"]
  }, {
    type: "debit",
    data: cards.value.debit,
    permissions: ["DASHBOARD.DEBIT.GET"]
  }, {
    type: "prepaid",
    data: cards.value.prepaid,
    permissions: ["DASHBOARD.PREPAID_EXPENSE.GET"]
  }, isShowCommissions && {
    type: "commission",
    data: cards.value.commission,
    permissions: ["DASHBOARD.COMMISSION.GET"]
  }, isVisibleByAllVerticals.value && {
    type: "cashFlow",
    data: cards.value.cashFlow,
    permissions: enabledStatistic.value ? ["DASHBOARD.CASH_FLOW.GET"] : ["STATISTICS.VIEW", "STATISTICS.VIEW.WEBMASTERS.OWN"]
  }].filter(Boolean));
  function setArray(data: Money | null) {
    return Array.isArray(data) ? data.map(item => item.value ?? item.count) : [data];
  }
  function getDifference(data: statisticCard) {
    return setArray(data.old).map((oldItem, idx) => {
      return setArray(data.new)[idx] - oldItem;
    });
  }
  function getPercentage(card: DifferenceCards) {
    const newData = setArray(card.data.new);
    const oldData = setArray(card.data.old);
    return oldData.map((oldItem, idx) => {
      if (card.percents) {
        return fixedFormatPercent(Math.abs(oldItem - newData[idx]));
      }
      if (newData[idx] === oldItem) {
        return 0;
      } else if (newData[idx] === 0 || oldItem === 0) {
        return 100;
      } else if (newData[idx] && oldItem) {
        return fixedFormatPercent(Math.abs(newData[idx] - oldItem) / newData[idx]);
      }
    });
  }
  onBeforeUnmount(() => {
    store.$reset();
  });
  return {
    defaultFormatter,
    currencySign,
    isVisibleByAllVerticals,
    currentVertical,
    dashboardDifferenceCards,
    getDifference,
    getPercentage
  };
};
__sfc_main.components = Object.assign({
  Permissions,
  DifferenceCard,
  DifferenceIndicator
}, __sfc_main.components);
export default __sfc_main;
