<template>
    <LFilters
        :filters="dashboardFilters"
        :loading="isLoading"
        auto-confirm
        @clear="SET_EMPTY('filters')"
        @confirm="confirmFilters">
        <template #filters>
            <div class="columns is-multiline is-real-mobile-flex">
                <FilterWrapper>
                    <CustomDatepicker
                        v-model="datepicker"
                        label="">
                    </CustomDatepicker>
                </FilterWrapper>

                <FilterWrapper>
                    <OffersSelect
                        v-model="offerId"
                        :vertical="systemVertical"
                        :placeholder="$t(`common.entity.filters.offer`)">
                    </OffersSelect>
                </FilterWrapper>
            </div>
        </template>

        <template #other>
            <b-radio
                :key="idx"
                v-for="(el, idx) in ['LEADS', 'APPROVE']"
                v-model="activeChart"
                class="p-1"
                :native-value="el"
                :type="`is-${ el === 'LEADS' ? 'info' : 'warning' }`">
                {{ $t(`advertiser.dashboard.table.labels.${ el.toLowerCase() }`) }}
            </b-radio>
        </template>
    </LFilters>
</template>

<script>
  import CustomDatepicker from "@/components/Common/Date/CustomDatepicker";
  import OffersSelect from "@/components/Common/Select/OffersSelect";
  import LFilters from "@/components/Common/LFilters";
  import { GET_STATISTIC, SET_EMPTY, UPDATE_OPTIONS } from "@core/store/action-constants";
  import { UPDATE_FILTERS } from "@core/store/mutation-constants";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "DashboardFilters",

    components: {
      CustomDatepicker,
      OffersSelect,
      LFilters
    },

    computed: {
      ...mapState("advertiser/dashboard", {
        dashboardFilters: ({ filters }) => filters
      }),

      ...mapState("advertiser/profile", {
        systemVertical: ({ user }) => user.systemVertical
      }),

      ...mapFields("filtersConfirm", {
        fields: ["datepicker", "offerId"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      ...mapFields("advertiser/dashboard", {
        fields: ["activeChart"],
        base: "options",
        action: UPDATE_OPTIONS
      }),

      isLoading () {
        return this.$wait(`advertiser/dashboard/${ GET_STATISTIC }`);
      }
    },

    methods: {
      ...mapActions("advertiser/dashboard", [
        UPDATE_FILTERS,
        GET_STATISTIC,
        SET_EMPTY
      ]),

      confirmFilters ({ filters }) {
        this.UPDATE_FILTERS(filters);
        this.GET_STATISTIC();
      }
    }
  };
</script>

<style scoped></style>